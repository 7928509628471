import '../css//App.css';
import { extendTheme } from '@chakra-ui/react';

import "aos/dist/aos.css";


const theme = extendTheme({
fonts: {
  heading: "neue-haas-grotesk-display",
  body: "proxima-nova",
},
});

export default theme;