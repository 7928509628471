import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import InnerArtists from './components/InnerArtists';
import Home from './components/Home';
import theme from "./components/Const";

const App = () => {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <Box minHeight="100vh" bg="black">
          {/* Navigation Links Example */}
          <nav>
            <Link to="/">Home</Link>
            <Link to="/InnerArtists">Inner Artists</Link>
          </nav>

          {/* Define Routes */}
          <Switch>
            <Route exact path="/" component={Home} />
            {/*<Route exact path="/InnerArtists" component={InnerArtists} />*/}
            {/*<Route path="/InnerArtists/:artistId" component={InnerArtists} />*/}
            {/* Define other routes as needed */}
          </Switch>
        </Box>
      </ChakraProvider>
    </Router>
  );
};

export default App;
