import React, {useState, useEffect, useRef} from 'react';
import {
    Box, Image, Text, Link, SimpleGrid, Heading, VStack, Modal, ModalOverlay,
    ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure
} from '@chakra-ui/react';
import ContentRenderer from './ContentRenderer';
import gradientbg from "../assets/images/purplegradientbg.svg";
import placeholderImage from "../assets/images/placeholder.jpg";
import {useParams} from 'react-router-dom';

function getTwitterHandle(twitterUrl) {
    return twitterUrl.split('/').pop()
}

function getShortBio(longBio) {
    return longBio.split("\n\n")[0]
}

const ArtistModal = ({artist, isOpen, onClose}) => {
    const [modalSize, setModalSize] = useState('70vw'); // Set initial modal size to 70% of viewport width

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            // Adjust modal size based on viewport width
            if (width < 600) {
                setModalSize('90vw');
            } else if (width < 900) {
                setModalSize('80vw');
            } else {
                setModalSize('70vw');
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial size on mount

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const twitterHandle = getTwitterHandle(artist[2]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay/>
            <ModalContent bg="gray" color="white" maxW={modalSize}> {/* Set maxW to modalSize */}
                <ModalHeader>{artist[0]}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6} maxHeight="80vh" overflowY="auto">
                    <VStack spacing={4} align="start">
                        <Image src={artist[1] || placeholderImage} alt={artist[0]} borderRadius="full" boxSize="150px"
                               objectFit="cover"/>
                        <ContentRenderer text={artist[5]}/>
                        <Link href={artist[2]} isExternal color="teal.300">@{twitterHandle}</Link>
                        {artist[3] && <Text>Discord: {artist[3]}</Text>}
                        {artist[4] ? <Image src={artist[4]} alt="Featured artwork" maxW="100%" my={2}/> : null}
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const InnerArtists = () => {
    const [artists, setArtists] = useState([]);
    const [introText, setIntroText] = useState("");
    const [selectedArtist, setSelectedArtist] = useState(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {artistId} = useParams();
    const artistRefs = useRef({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets/1W8nkzZzmTkzUxiEtfU6ofrJLP9seeb9qgDGigtSw0vM/values/Sheet1?alt=json&key=AIzaSyA0K48Iu5yMlYdbTstcJMdNDH2dKt1JS-I');
            const data = await response.json();
            setArtists(data.values.slice(1)); // Skip the header row
        };

        const fetchIntroText = async () => {
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets/1W8nkzZzmTkzUxiEtfU6ofrJLP9seeb9qgDGigtSw0vM/values/Sheet2?alt=json&key=AIzaSyA0K48Iu5yMlYdbTstcJMdNDH2dKt1JS-I');
            const data = await response.json();
            setIntroText(data.values[1][0]); // Assuming the intro text is in the second row, first column
        };

        fetchData().catch(console.error);
        fetchIntroText().catch(console.error);
    }, []);

    useEffect(() => {
        if (artistId && artists.length > 0) {
            const normalizedArtistId = artistId.toLowerCase();
            const artist = artists.find((a) => getTwitterHandle(a[2]).toLowerCase() === normalizedArtistId);
            if (artist && artistRefs.current[normalizedArtistId]) {
                artistRefs.current[normalizedArtistId].scrollIntoView({behavior: 'smooth'});
                setSelectedArtist(artist);
                onOpen();
            }
        }
    }, [artistId, artists, onOpen]);

    const handleArtistClick = (artist) => {
        window.history.replaceState(null, null, "/InnerArtists/" + getTwitterHandle(artist[2]));
        setSelectedArtist(artist);
        onOpen();
    };

    const handleClose = () => {
        onClose(); // Close the modal
        window.history.replaceState(null, null, "/InnerArtists"); // Reset the URL to the base URL
        setSelectedArtist(null); // Clear the selected artist
    };

    return (
        <Box minHeight="100vh" bgImage={`url(${gradientbg})`} bgPosition="center" bgRepeat="no-repeat" bgSize="cover">

            {/* Intro Section */}
            <Box p={5} maxW="3xl" mx="auto" mt={6} mb={8}>
                <Box
                    p={6}
                    bg="blackAlpha.500"
                    borderRadius="lg"
                    shadow="md"
                    color="white"
                    textAlign="left"
                    borderWidth="1px"
                >
                    <Heading as="h2" fontSize="2xl" mb={4} color="teal.300">
                        888 Inner Artist Profiles
                    </Heading>
                    <ContentRenderer text={introText}/>
                </Box>
            </Box>

            <SimpleGrid columns={[1, 2, 3]} spacing="40px" p={5}>
                {artists.map((artist, index) => {
                    const normalizedArtistId = getTwitterHandle(artist[2]).toLowerCase();
                    const shortBio = getShortBio(artist[5])
                    return (
                        <Box
                            key={index}
                            ref={(el) => {
                                artistRefs.current[normalizedArtistId] = el;
                            }}
                            p={5}
                            shadow="md"
                            borderWidth="1px"
                            borderRadius="lg"
                            overflow="hidden"
                            bg="blackAlpha.500"
                            onClick={() => handleArtistClick(artist)}
                            cursor="pointer"
                            _hover={{
                                transform: 'scale(1.05)',
                                transition: '0.3s ease-in-out'
                            }} // Hover effect for scaling
                            transition="0.3s ease-in-out" // Smooth transition
                        >
                            <VStack>
                                <Heading fontSize="xl" color="white">{artist[0]}</Heading>
                                <Image
                                    src={artist[1] || placeholderImage}
                                    alt={artist[0]}
                                    borderRadius="full"
                                    boxSize="100px"
                                    objectFit="cover"
                                    _hover={{boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)'}} // Image hover shadow
                                />
                                <Text noOfLines={4} color="white">{shortBio}</Text>
                                <Link
                                    href={artist[2]}
                                    isExternal
                                    color="teal.300"
                                    _hover={{color: 'teal.100', textDecoration: 'underline'}} // Hover effect on link
                                >
                                    @{artist[2].split('/').pop()}
                                </Link>
                                {artist[3] && <Text color="white">Discord: {artist[3]}</Text>}
                                <Image src={artist[4] || placeholderImage} alt="Featured artwork" boxSize="200px"
                                       objectFit="cover"/>
                            </VStack>
                        </Box>
                    );
                })}
            </SimpleGrid>
            {selectedArtist && <ArtistModal artist={selectedArtist} isOpen={isOpen} onClose={handleClose}/>}
        </Box>
    );
};

export default InnerArtists;
